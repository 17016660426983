/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAoi = /* GraphQL */ `
  mutation CreateAoi($input: CreateAoiInput!, $condition: ModelAoiConditionInput) {
    createAoi(input: $input, condition: $condition) {
      id
      title
      points
      firesub
      layers {
        items {
          id
          title
          type
          time
          content
          createdAt
          updatedAt
          aoiLayersId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAoi = /* GraphQL */ `
  mutation UpdateAoi($input: UpdateAoiInput!, $condition: ModelAoiConditionInput) {
    updateAoi(input: $input, condition: $condition) {
      id
      title
      points
      firesub
      layers {
        items {
          id
          title
          type
          time
          content
          createdAt
          updatedAt
          aoiLayersId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAoi = /* GraphQL */ `
  mutation DeleteAoi($input: DeleteAoiInput!, $condition: ModelAoiConditionInput) {
    deleteAoi(input: $input, condition: $condition) {
      id
      title
      points
      firesub
      layers {
        items {
          id
          title
          type
          time
          content
          createdAt
          updatedAt
          aoiLayersId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLayer = /* GraphQL */ `
  mutation CreateLayer($input: CreateLayerInput!, $condition: ModelLayerConditionInput) {
    createLayer(input: $input, condition: $condition) {
      id
      title
      aoi {
        id
        title
        points
        firesub
        layers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      type
      time
      content
      createdAt
      updatedAt
      aoiLayersId
      owner
    }
  }
`;
export const updateLayer = /* GraphQL */ `
  mutation UpdateLayer($input: UpdateLayerInput!, $condition: ModelLayerConditionInput) {
    updateLayer(input: $input, condition: $condition) {
      id
      title
      aoi {
        id
        title
        points
        firesub
        layers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      type
      time
      content
      createdAt
      updatedAt
      aoiLayersId
      owner
    }
  }
`;
export const deleteLayer = /* GraphQL */ `
  mutation DeleteLayer($input: DeleteLayerInput!, $condition: ModelLayerConditionInput) {
    deleteLayer(input: $input, condition: $condition) {
      id
      title
      aoi {
        id
        title
        points
        firesub
        layers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      type
      time
      content
      createdAt
      updatedAt
      aoiLayersId
      owner
    }
  }
`;
