import React, { useState } from 'react';
import { fetchAoisThunk, updateAoiFireSubscription } from '../../../../features/Api-thunk/api.service';
import { updateAoi } from '../../../../graphql/mutations';
import { listAois } from '../../../../graphql/queries';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import { toast } from 'react-toastify';

export interface RealTimeAlgoProps {
  existAOI?: AreaOfInterest[];
  apps?: string[];
}

const RealTimeAlgo: React.FC<RealTimeAlgoProps> = () => {
  const [selectedApp, setSelectedApp] = useState('');
  const [selectedAOI, setSelectedAOI] = useState('');

  const aois: any = useAppSelector((state) => state.aoiReducer.aois);

  const dispatch = useAppDispatch();

  const handleCTA = () => {
    if (selectedAOI !== '' && selectedApp !== '') {
      addSubscription();
    }
  };

  const aoisForDropdown = (aois: any) => {
    return aois.filter((aoi: any) => !aoi.firesub).map((aoi: any) => aoi.title);
  };

  const addSubscription = () => {
    dispatch(
      updateAoiFireSubscription({
        operation: updateAoi,
        payload: { id: aois.filter((aoi: any) => aoi.title === selectedAOI)[0].id, firesub: true },
      })
    )
      .then(() => {
        dispatch(fetchAoisThunk(listAois));
        toast.success(`${selectedAOI} subscribed to Fire Detection`);
      })
      .catch((err) => {
        toast.error(`${selectedAOI} failded to subscribed Fire Detection`);
      });
  };

  return (
    <div className='real-time-algorithm'>
      <div>
        <p>Algorithms:</p>
        <Dropdown handleSelection={setSelectedApp} options={['Fire Detection']} placeholder='Choose App ' />
        <p>Areas of Interest:</p>
        <Dropdown
          handleSelection={setSelectedAOI}
          options={aoisForDropdown(aois)}
          placeholder='Choose Area Of Interest'
        />
      </div>
      <Button
        text='Subscribe'
        disable={selectedApp === '' || selectedAOI === ''}
        handleClick={handleCTA}
        isTextSecondaryColor
      />
    </div>
  );
};

export default RealTimeAlgo;
