import { FC } from 'react';

export interface ButtonProps {
  text: string;
  handleClick: () => void;
  icon?: string;
  isTextSecondaryColor?: boolean;
  disable?: boolean;
  isButtonSecondaryColor?: boolean;
}

const Button: FC<ButtonProps> = ({
  text,
  handleClick,
  icon,
  disable,
  isTextSecondaryColor,
  isButtonSecondaryColor,
}) => {
  const buttonType = `${isTextSecondaryColor && 'secondary-text-color'} ${isButtonSecondaryColor && 'secondary-color'}`;

  const clickHandler = () => {
    if (!disable) {
      handleClick();
    }
  };

  return (
    <button onClick={clickHandler} disabled={disable} className={`button ${buttonType}`}>
      {icon && (
        <span className='icon'>
          <img alt={`icon-${icon}`} src={`./icons/${icon}`}></img>
        </span>
      )}
      {text}
    </button>
  );
};

export default Button;
