import ArcBaseMap from '@arcgis/core/Map';
import ArcMapView from '@arcgis/core/views/MapView';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import config from '../../config/keys.json';
import esriConfig from '@arcgis/core/config';

export const initMap = () => {
  esriConfig.apiKey = config.API_KEY;
  const graphicLayer = new GraphicsLayer();
  const Map = new ArcBaseMap({
    basemap: 'hybrid',
    layers: [graphicLayer],
  });

  const MapView = new ArcMapView({
    constraints: {
      minZoom: 3,
      rotationEnabled: false,
    },
    container: null,
    popup: {
      actions: ['hover'],
    },
    map: Map,
  });
  return [Map, MapView, graphicLayer];
};
