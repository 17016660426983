import { useEffect, useState } from 'react';
import SecondarySidebar from './components/SecondarySidebar/SecondarySidebar';
import SidebarContent from './components/SidebarContent/SidebarContent';
import { mockActivities } from '../../mock/sidebarActivities';
import { NewAoi } from '../newAoi/NewAoi';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { resetAoiStatus } from '../../features/aoi/AoiSlice';
import Loader from '../loader/Loader';

export const SidebarMenu = () => {
  const createAoiState = useAppSelector((state) => state.aoiReducer.createAoiState);
  const aoiStatus = useAppSelector((state) => state.aoiReducer.status);
  const dispatch = useAppDispatch();

  const [sidebarActivity, setSidebarActivity] = useState<SidebarActivity>(mockActivities[0]);

  useEffect(() => {
    if (aoiStatus === 'fulfilled') {
      setSidebarActivity(mockActivities[0]);
      dispatch(resetAoiStatus());
    }
  }, [aoiStatus, dispatch]);

  const changeSidebarActivity = (activity: SidebarActivity) => {
    setSidebarActivity(activity);
  };

  return (
    <div className='sidebar'>
      {aoiStatus === 'pending' ? (
        <Loader />
      ) : createAoiState ? (
        <NewAoi polygon={createAoiState} />
      ) : (
        <>
          <SecondarySidebar
            activities={mockActivities}
            activeOption={sidebarActivity}
            setActivityFunc={changeSidebarActivity}
          />
          <SidebarContent activity={sidebarActivity} />
        </>
      )}
    </div>
  );
};
