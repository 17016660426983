import { createSlice } from '@reduxjs/toolkit';
import { initMap } from './Map.service';
const [Map, MapView, graphicLayer] = initMap();
const initialState = {
  Map: Map as any,
  MapView: MapView as any,
  isMapLoaded: false,
  graphicLayer: graphicLayer as any,
  layersIds: [] as any,
};
export const MapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setIsMapLoaded: (state, { payload }) => {
      state.isMapLoaded = payload.isLoaded;
    },
    removeSketches: (state) => {
      state.graphicLayer.remove(state.graphicLayer.graphics.items[0]);
    },
    updateLayers: (state, { payload }) => {
      const { layer }: any = payload;
      const temp: any[] = [];
      if (payload.isChecked) {
        if (layer.kml) {
          if (payload.isAoi) {
            temp.push(state.Map.layers.shift());
            while (temp[temp.length - 1].type !== 'kml' && state.Map.layers._items.length)
              temp.push(state.Map.layers.shift());
            if (temp[temp.length - 1]?.type === 'kml') state.Map.layers.unshift(temp.pop());
            state.Map.layers.unshift(layer.kml);
            while (temp.length) state.Map.layers.unshift(temp.pop());
            state.MapView.goTo(layer.kml.fullExtent);
          } else {
            // fire detection
            if (layer.kml.type === 'kml') {
              state.Map.layers.add(layer.kml);
              state.MapView.goTo(layer.kml.fullExtent);
            }
            // biomass
            else {
              temp.push(state.Map.layers.shift());
              while (temp[temp.length - 1].type !== 'kml' && state.Map.layers._items.length)
                temp.push(state.Map.layers.shift());
              if (temp[temp.length - 1]?.type === 'kml') state.Map.layers.unshift(temp.pop());
              state.Map.layers.unshift(layer.kml);
              while (temp.length) state.Map.layers.unshift(temp.pop());
              state.MapView.goTo(layer.parentAoi.fullExtent);
            }
          }
          state.layersIds = [...state.layersIds, layer.id];
        }
      } else {
        const layerToRemove = state.Map.layers.find((mapLayer: any) => mapLayer.id.split('_')[0] === layer.id);
        state.Map.layers.remove(layerToRemove);
        state.layersIds = state.layersIds.filter((id: any) => id !== layer.id);
      }
    },
    updateListLayers: (state, { payload }) => {
      const { layers }: any = payload;
      const temp: any[] = [];
      layers.forEach((layer: any) => {
        if (payload.isChecked) {
          if (layer.kml && !state.layersIds.includes(layer.id)) {
            // fire detection
            if (layer.kml.type === 'kml') state.Map.layers.add(layer.kml);
            // biomass
            else {
              temp.push(state.Map.layers.shift());
              state.Map.layers.unshift(layer.kml);
              state.Map.layers.unshift(temp.shift());
            }
            state.layersIds = [...state.layersIds, layer.id];
            state.MapView.goTo(layer.kml.fullExtent);
          }
        } else {
          if (state.layersIds.includes(layer.id)) {
            const layerToRemove = state.Map.layers.find((mapLayer: any) => mapLayer.id.split('_')[0] === layer.id);
            state.Map.layers.remove(layerToRemove);
            state.layersIds = state.layersIds.filter((id: any) => id !== layer.id);
          }
        }
      });
    },
    setMap: (state, { payload }) => {
      state.Map = payload.map;
    },
    setMapView: (state, { payload }) => {
      state.MapView = payload.mapView;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsMapLoaded, setMap, setMapView, updateListLayers, updateLayers, removeSketches } = MapSlice.actions;

export default MapSlice.reducer;
