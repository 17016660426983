import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';

import 'react-datepicker/dist/react-datepicker.css';
import { sendAnalysisRequest } from '../../../../features/Api-thunk/api.service';
import { toast } from 'react-toastify';

export interface AnalysisProps {
  applications: string[];
  areasOfInterest: AreaOfInterest[];
}

const Analysis = () => {
  const [selectedApp, setSelectedApp] = useState<any>('');
  const [selectedAOI, setSelectedAOI] = useState('');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const aois: any = useAppSelector((state) => state.aoiReducer.aois);

  const dispatch = useAppDispatch();

  const handleCTA = () => {
    if (selectedApp && selectedAOI && startDate && endDate) {
      const aoi = aois.find((a: any) => a.title === selectedAOI);
      dispatch(
        sendAnalysisRequest({
          service: selectedApp === 'Forest Biomass' ? 'biomass' : 'fire',
          start_time: startDate.toISOString(),
          end_time: endDate.toISOString(),
          aoi,
        })
      )
        .then(() => {
          toast.info(
            'Analysis request was successfully received, analysis layers will be received in the next few minutes'
          );
        })
        .catch(() => {
          toast.error('Analysis request was failed, please try again');
        });
    }
  };

  const aoisForDropdown = (aois: AreaOfInterest[]) => {
    return aois.map((aoi: any) => aoi.title);
  };

  // if aois equal to null => return nothing (for loading)
  if (aois == null) {
    return <></>;
  }
  return (
    <div className='analysis'>
      <div>
        <p className='form-field-title'>Services:</p>

        <Dropdown
          handleSelection={setSelectedApp}
          options={['Forest Biomass', 'Fire Detection']}
          placeholder='Choose App '
        />
        <p className='form-field-title'>Areas of Interest:</p>
        <Dropdown
          handleSelection={setSelectedAOI}
          options={aoisForDropdown(aois)}
          placeholder='Choose Area Of Interest'
        />

        <p className='form-field-title'>From Date:</p>
        <DatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          selectsStart
          maxDate={endDate ? endDate : new Date()}
          maxTime={
            startDate &&
            endDate &&
            startDate.getFullYear() === endDate.getFullYear() &&
            startDate.getMonth() === endDate.getMonth() &&
            startDate.getDate() === endDate.getDate()
              ? endDate
              : new Date(2022, 0, 1, 23, 59)
          }
          minTime={new Date(2022, 0, 1, 0, 0)}
          className='date-picker'
          dateFormat='MM/dd/yyyy'
          timeFormat='HH:mm'
          timeIntervals={60}
          showTimeSelect
          formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
          customInput={
            <div className='date-container'>
              {startDate ? (
                <p>{`${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()} ${
                  (startDate.getHours() < 10 ? '0' : '') + startDate.getHours()
                }:${(startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()}`}</p>
              ) : (
                <p className='date-placeholder'>mm/dd/yyyy HH:mm</p>
              )}
              <img
                className={`calander-image ${!startDate && 'date-placeholder'}`}
                alt='date'
                src='./icons/date.svg'
              ></img>
            </div>
          }
        />
        <p className='form-field-title'>To Date:</p>
        <DatePicker
          selected={endDate}
          onChange={(date: any) => {
            setEndDate(date);
            if (!startDate) setStartDate(date);
          }}
          selectsEnd
          className='date-picker'
          dateFormat='MM/dd/yyyy HH:mm'
          timeFormat='HH:mm'
          timeIntervals={60}
          minDate={startDate}
          minTime={
            startDate &&
            endDate &&
            startDate.getFullYear() === endDate.getFullYear() &&
            startDate.getMonth() === endDate.getMonth() &&
            startDate.getDate() === endDate.getDate()
              ? startDate
              : new Date(2022, 0, 1, 0, 0)
          }
          maxDate={new Date()}
          maxTime={
            endDate &&
            new Date().getFullYear() === endDate.getFullYear() &&
            new Date().getMonth() === endDate.getMonth() &&
            new Date().getDate() === endDate.getDate()
              ? new Date()
              : new Date(2022, 0, 1, 23, 59)
          }
          showTimeSelect
          formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
          customInput={
            <div className='date-container'>
              {endDate ? (
                <p>{`${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()} ${
                  (endDate.getHours() < 10 ? '0' : '') + endDate.getHours()
                }:${(endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()}`}</p>
              ) : (
                <p className='date-placeholder'>mm/dd/yyyy HH:mm</p>
              )}
              <img
                className={`calander-image ${!endDate && 'date-placeholder'}`}
                alt='date'
                src='./icons/date.svg'
              ></img>
            </div>
          }
        />
      </div>
      <div>
        <AnalysisComment service={selectedApp} />
        <Button
          text='Analyze'
          disable={selectedApp === '' || selectedAOI === '' || startDate === null || endDate === null}
          handleClick={handleCTA}
          isTextSecondaryColor
        />
      </div>
    </div>
  );
};

export default Analysis;

const AnalysisComment: React.FC<{ service: any }> = ({ service }) => {

  if (service === '') {
    return <></>;
  } else {
    return (
      <div className='comment-about-service'>
        <img className='error-icon' alt="error-icon" src='./icons/error.svg' />
        {service === 'Forest Biomass' ? (
          <p>The analysis for the “Forest Biomass” service will produce up to 10 layers.</p>
        ) : (
          <div>
            <p>
              For GOES based imagery, the result layers will be spread evenly with equal intervals over the time-range
              selected by the user.
            </p>
            <p>
              {' '}
              For MODIS based imagery, the result layers will be selected randomly over the time-range selected by the
              user.
            </p>
          </div>
        )}
      </div>
    );
  }
};
