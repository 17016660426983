/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAoi = /* GraphQL */ `
  query GetAoi($id: ID!) {
    getAoi(id: $id) {
      id
      title
      points
      firesub
      layers {
        items {
          id
          title
          type
          time
          content
          createdAt
          updatedAt
          aoiLayersId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAois = /* GraphQL */ `
  query ListAois($filter: ModelAoiFilterInput, $limit: Int, $nextToken: String) {
    listAois(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        points
        firesub
        layers {
          items {
            id
            title
            type
            time
            content
            createdAt
            updatedAt
            aoiLayersId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLayer = /* GraphQL */ `
  query GetLayer($id: ID!) {
    getLayer(id: $id) {
      id
      title
      aoi {
        id
        title
        points
        firesub
        layers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      type
      time
      content
      createdAt
      updatedAt
      aoiLayersId
      owner
    }
  }
`;
export const listLayers = /* GraphQL */ `
  query ListLayers($filter: ModelLayerFilterInput, $limit: Int, $nextToken: String) {
    listLayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        aoi {
          id
          title
          points
          firesub
          createdAt
          updatedAt
          owner
        }
        type
        time
        content
        createdAt
        updatedAt
        aoiLayersId
        owner
      }
      nextToken
    }
  }
`;
