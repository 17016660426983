import { Authenticator, SelectField } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import { Amplify, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { HomePage } from './pages/homePage/HomePage';
import countries from './mock/countries.json';
import '@aws-amplify/ui-react/styles.css';
import { ConditoionsModal } from './components/conditionsAndTerms/ConditoionsModal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

Amplify.configure(awsExports);

export default function App() {
  const [isStep2State, setIsStep2State] = useState(false);
  const [country, setCountry] = useState('');
  const [options, setOptions]: any = useState([]);
  const [userType, setUserType] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  useEffect(() => {
    setOptions(countries.map((item) => item.country));
  }, []);

  const components = {
    Header() {
      return (
        <div className='logo-container'>
          <img className='logo-img' alt='Tsl Logo' src={`./icons/big-logo.png`} />
          <span className='terms-button'>
            By logging in you agree to our{' '}
            <span className='clickable' onClick={(e) => setIsTermsOpen(true)}>
              privacy policy & terms of use
            </span>
          </span>
        </div>
      );
    },
    ForceNewPassword: {
      FormFields() {
        setTimeout(() => {
          if (!isStep2State) {
            document.getElementsByTagName('h3')[0].style.display = 'none';
            Array.from(
              document.getElementsByClassName('amplify-button--primary') as HTMLCollectionOf<HTMLElement>
            )[0].style.display = 'none';
          } else {
            document.getElementsByTagName('h3')[0].style.display = 'block';
            Array.from(
              document.getElementsByClassName('amplify-button--primary') as HTMLCollectionOf<HTMLElement>
            )[0].style.display = 'block';
          }
        }, 0);
        return (
          <>
            <div className='wise-step'>
              <div className='step'>
                <img alt='step1' className='step-img' src={`./icons/step_1.png`} />
                <span className='step-label'>General information</span>
              </div>
              <div className='hr'>.............................................</div>
              {!isStep2State && (
                <div className='step'>
                  <img alt='step2' className='step-img' src={`icons/step_2_unclicked.png`} />
                  <span className='step-label'>Reset password</span>
                </div>
              )}
              {isStep2State && (
                <div className='step'>
                  <img alt='step2clicked' className='step-img' src={`./icons/step_2_clicked.png`} />
                  <span className='step-label'>Reset password</span>
                </div>
              )}
            </div>
            {!isStep2State && (
              <>
                <h3>General information</h3>
                <SelectField
                  label=''
                  labelHidden
                  placeholder='Country'
                  value={country}
                  name='custom:country'
                  autoFocus
                  options={options}
                  onChange={(e) => setCountry(e.target.value)}
                ></SelectField>
                <SelectField
                  label=''
                  placeholder='User type'
                  labelHidden
                  name='custom:user_type'
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  options={['Government', 'Forest Owner', 'Farmer', 'Research', 'Commercial Entity']}
                />
                {isRequired && <div className='error-message'>You must fill all fields!</div>}
                <button
                  onClick={() => setIsStep2State(!isStep2State)}
                  disabled={!country || !userType}
                  className='auth-button'
                >
                  Continue
                </button>
              </>
            )}
            {isStep2State && (
              <>
                <Authenticator.ForceNewPassword.FormFields />
                {/* <div>
                  <Button
                    fontWeight="normal"
                    onClick={() => {
                      setIsStep2State(false);
                    }}
                    size="small"
                    variation="link"
                    className="forgot-btn"
                  >
                    {`<- Back`}
                  </Button>
                </div> */}
              </>
            )}
          </>
        );
      },
    },
  };

  const services = {
    async handleSignIn(formData: any) {
      let { username, password } = formData;
      setIsRequired(false);
      setIsStep2State(false);
      setCountry('');
      setUserType('');
      return Auth.signIn({
        username,
        password,
      });
    },
  };

  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: 'Enter email or user name',
        isRequired: true,
        label: 'Sign In',
      },
    },
  };

  return (
    <>
      <Popup className='terms-modal' open={isTermsOpen}>
        <ConditoionsModal
          closePopup={() => {
            setIsTermsOpen(false);
          }}
        ></ConditoionsModal>
      </Popup>
      <Authenticator hideSignUp components={components} formFields={formFields} services={services}>
        {({ signOut, user }) => (
          <div className='app-container'>
            <main>
              <HomePage />
            </main>
          </div>
        )}
      </Authenticator>
    </>
  );
}
