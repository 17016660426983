import { FC } from 'react';
import Button from '../sidebarMenu/components/Button/Button';
import './_confirmation-popup.scss';

export const ConfirmationPopup: FC<{ text: string; closePopup: () => void; confirmFunction: () => void }> = ({
  text,
  closePopup,
  confirmFunction,
}) => {
  return (
    <div className='confirmation-popup'>
      <img className='exit-icon' onClick={closePopup} src='./icons/x.svg' alt='exit' />
      <p>{text}</p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          handleClick={() => {
            confirmFunction();
            closePopup();
          }}
          text='Yes'
          isTextSecondaryColor
        />
        <Button handleClick={closePopup} text='No' isTextSecondaryColor />
      </div>
    </div>
  );
};
