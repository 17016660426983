import { FC } from 'react';
import { terms } from './terms';

import './_conditions-popup.scss';

export const ConditoionsModal: FC<{ closePopup: () => void }> = ({ closePopup }) => {
  return (
    <div className='conditions-popup'>
      <img className='exit-icon' onClick={closePopup} src='./icons/x.svg' alt='exit' />
      <div className='content' dangerouslySetInnerHTML={{ __html: terms }}></div>
    </div>
  );
};
