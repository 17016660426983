import { useEffect } from 'react';
import { SidebarMenu } from '../../components/sidebarMenu/SidebarMenu';
import { BaseMap } from '../../components/arcgisMap/BaseMap';
import { deleteLayerResult, fetchAoisThunk } from '../../features/Api-thunk/api.service';
import { listAois } from '../../graphql/queries';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import Popup from 'reactjs-popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import { FirePopup } from '../../components/firePopup/FirePopup';
import { closeAnalysisToast, closeFireAlarm } from '../../features/aoi/AoiSlice';

export const HomePage = () => {
  const dispatch = useAppDispatch();

  const isFireModal = useAppSelector((state) => state.aoiReducer.fireAlarm);
  const isNewAnalysisLayers = useAppSelector((state) => state.aoiReducer.analysisStatus);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 120000);
    // set interval to fetch data every 5 min

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    (async () => {
      if (isNewAnalysisLayers.length > 0) {
        isNewAnalysisLayers.map(async (notification: any) => {
          toast.info(`${notification.content}`, {
            onClose: async () => {
              dispatch(closeAnalysisToast(notification.id));
              await deleteLayerResult(notification.id);
            },
          });
        });
      }
    })();
  }, [isNewAnalysisLayers]);

  // refresh the data in the website
  const fetchData = async () => {
    try {
      await dispatch(fetchAoisThunk(listAois));
    } catch (err) {
      toast.error('Failed to fetch data');
    }
  };

  return (
    <div className='home-page-container flex'>
      <SidebarMenu />
      <div className='map-container'>
        <BaseMap basemap={'hybrid'} zoom={4} />
        <ToastContainer
          position='bottom-right'
          autoClose={false}
          hideProgressBar={true}
          limit={3}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <img className='power-by' src='./icons/by-moveo.png' alt='moveo' />
      </div>
      <Popup open={isFireModal}>
        <FirePopup closePopup={() => dispatch(closeFireAlarm())} />
      </Popup>
    </div>
  );
};
