import React, { useEffect, Fragment, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import Legend from '@arcgis/core/widgets/Legend';
import Search from '@arcgis/core/widgets/Search';
import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import Sketch from '@arcgis/core/widgets/Sketch';
import CoordinateConversion from '@arcgis/core/widgets/CoordinateConversion';
import './_base-map.scss';
import { createAoiExit, createAoiStarted } from '../../features/aoi/AoiSlice';
import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import { setIsMapLoaded } from '../../features/map/MapSlice';

interface BasemapProps {
  basemap: any;
  zoom: any;
}

export const BaseMap: React.FC<BasemapProps> = ({ basemap, zoom = 4 }) => {
  const mapRef: any = useRef();
  const Map: any = useAppSelector((state: any) => state.mapReducer.Map);
  const MapView: any = useAppSelector((state: any) => state.mapReducer.MapView);
  const isMapLoaded = useAppSelector((state: any) => state.mapReducer.isMapLoaded);
  const GraphicalLayer: any = useAppSelector((state: any) => state.mapReducer.graphicLayer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (MapView) {
      MapView.container = mapRef.current;
      if (!isMapLoaded) {
        initMapOptions();
      }
    }
  }, [MapView]);

  const initMapOptions = () => {
    MapView.when(() => {
      dispatch(setIsMapLoaded({ isLoaded: true }));

      // moving zoom controls
      MapView.ui.move('zoom', 'bottom-right');

      // adding location search to the view
      const searchWidget = new Search({
        view: MapView,
        locationEnabled: false,
      });
      MapView.ui.add(searchWidget, {
        position: 'top-left',
        index: 2,
      });

      // adding coordinates to the view
      const ccWidget = new CoordinateConversion({
        view: MapView,
        multipleConversions: false,
        visibleElements: { editButton: false },
      });
      MapView.ui.add(ccWidget, 'bottom-right');

      // adding map scale to the view
      const scaleBar = new ScaleBar({
        view: MapView,
        unit: 'dual',
      });
      MapView.ui.add(scaleBar, {
        position: 'bottom-right',
      });

      // adding user shapes draw
      const sketch = new Sketch({
        layer: GraphicalLayer,
        view: MapView,
        creationMode: 'update',
        availableCreateTools: ['polygon', 'rectangle'],
        visibleElements: {
          settingsMenu: false,
          undoRedoMenu: false,

          selectionTools: {
            'lasso-selection': false,
            'rectangle-selection': false,
          },
        },
      });

      sketch.on('create', (event) => {
        if (GraphicalLayer.graphics.items.length > 1) {
          GraphicalLayer.remove(GraphicalLayer.graphics.items[0]);
        }
        if (event.state === 'complete' && event.graphic.geometry) {
          const polygon = webMercatorToGeographic(event.graphic.geometry, true).toJSON().rings[0];
          if (polygon) {
            dispatch(createAoiStarted({ polygon }));
          }
        }
      });

      sketch.on('update', (event) => {
        const polygon = webMercatorToGeographic(event.graphics[0].geometry, true).toJSON().rings[0];
        if (polygon && event.state === 'active') {
          dispatch(createAoiStarted({ polygon }));
        }
      });

      sketch.on('delete', (event) => {
        dispatch(createAoiExit());
      });

      MapView.ui.add(sketch, 'top-right');

      Map.layers.on('change', (ev: any) => {
        const legendToRemove = MapView.ui._components.find((comp: any) => comp.id === 'legend');
        if (ev.target.items.length > 1) {
          if (legendToRemove) MapView.ui.remove(legendToRemove);
          if (ev.target.items[1].id.split('_')[1] === 'biomass') {
            const legend = new Legend({
              id: 'legend',
              view: MapView,
              layerInfos: [
                {
                  layer: ev.target.items[1],
                },
              ],
            });
            MapView.ui.add(legend, 'bottom-left');
          }
        } else {
          MapView.ui.remove(legendToRemove);
        }
      });
    });
  };

  return (
    <Fragment>
      <div className='arcgis-map' ref={mapRef}></div>
    </Fragment>
  );
};
