import { FC } from 'react';
import Algorithms from '../Algorithms/Algorithms';
import Layers from '../Layers/Layers';
import { useAppSelector } from '../../../../store/hooks/hooks';
import Loader from '../../../loader/Loader';

export interface SidebarContentProps {
  activity: SidebarActivity;
}

const SidebarContent: FC<SidebarContentProps> = ({ activity }) => {
  const aois = useAppSelector((state) => state.aoiReducer.aois);

  const renderActivity = (activity: SidebarActivity) => {
    switch (activity.name) {
      case 'layers':
        if (aois) {
          return <Layers interestAreas={aois} />;
        } else {
          return <Loader />;
        }
      case 'algorithms':
        return <Algorithms />;
    }
  };

  return <div className='sidebar-content'>{renderActivity(activity)}</div>;
};

export default SidebarContent;
