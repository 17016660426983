import { FC, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Button from '../Button/Button';
import './_filter.scss';

const Filter: FC<{ isOpen: boolean; handleAddFilter: Function; handleCloseFilter: Function }> = ({
  isOpen,
  handleAddFilter,
  handleCloseFilter,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate]: any = dateRange;
  const [fireState, setFireState] = useState(false);
  const [biomassState, setBiomassState] = useState(false);

  const handleApplyFilter = () => {
    let filters = [];
    if (startDate && endDate) {
      endDate.setDate(endDate.getDate() + 1);
      filters.push({ date: { start: startDate, end: endDate } });
    }
    if ((fireState && !biomassState) || (!fireState && biomassState)) {
      fireState ? filters.push({ service: 'Fire Detection' }) : filters.push({ service: 'Biomass' });
    }
    setDateRange([null, null]);
    setFireState(false);
    setBiomassState(false);
    handleAddFilter(filters);
    handleCloseFilter();
  };

  return (
    <div className={`filter-container ${!isOpen && 'closed'}`}>
      <img className='exit-icon' onClick={() => handleCloseFilter()} src='./icons/x.svg' alt='exit' />
      <h3 className='filter-title'>Filter</h3>
      <p className='form-field-title'>Date:</p>
      <ReactDatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update: any) => setDateRange(update)}
        maxDate={new Date()}
        className='date-picker'
        dateFormat='MM/dd/yyyy'
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        customInput={
          <div className='date-container'>
            {startDate && endDate ? (
              <p>
                {`${startDate.getMonth()}/${startDate.getDate()}/${startDate.getFullYear()}`}-
                {`${endDate.getMonth()}/${endDate.getDate()}/${endDate.getFullYear()}`}
              </p>
            ) : (
              <p>mm/dd/yyyy - mm/dd/yyyy </p>
            )}
            <img alt='date' src='./icons/date.svg'></img>
          </div>
        }
      />
      <p className='form-field-title '>Services:</p>
      <div className='checkbox-container'>
        <input
          type='checkbox'
          id='layer-checkbox'
          name='interest'
          value={'Fire Detection'}
          checked={fireState}
          onChange={() => {
            setFireState((prev) => !prev);
          }}
        />
        <p>Fire Detection</p>
      </div>
      <div className='checkbox-container'>
        <input
          type='checkbox'
          id='layer-checkbox'
          name='interest'
          value={'Biomass'}
          checked={biomassState}
          onChange={() => {
            setBiomassState((prev) => !prev);
          }}
        />
        <p>Forest Biomass</p>
      </div>
      <Button
        text='Apply'
        disable={!(fireState || biomassState || (!!startDate && !!endDate))}
        isTextSecondaryColor={true}
        handleClick={handleApplyFilter}
      />
    </div>
  );
};

export default Filter;
