import KMLLayer from '@arcgis/core/layers/KMLLayer';
import ImageryTileLayer from '@arcgis/core/layers/ImageryTileLayer';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import { layersCount } from '../../utils/layers';
let renderer = new ClassBreaksRenderer({
  field: 'Pixel Value',
});
renderer.addClassBreakInfo({
  minValue: 0,
  maxValue: 70,
  label: '0 - 70  t/ha',
  symbol: {
    type: 'simple-fill',
    color: [256, 250, 204, 255],
  },
});
renderer.addClassBreakInfo({
  minValue: 70.5,
  maxValue: 140,
  label: '71 - 140  t/ha',
  symbol: {
    type: 'simple-fill',
    color: [189, 184, 107, 255],
  },
});
renderer.addClassBreakInfo({
  minValue: 140.5,
  maxValue: 210,
  label: '141 - 210  t/ha',
  symbol: {
    type: 'simple-fill',
    color: [128, 179, 71, 255],
  },
});
renderer.addClassBreakInfo({
  minValue: 210.5,
  maxValue: 280,
  label: '211 - 280 t/ha',
  symbol: {
    type: 'simple-fill',
    color: [97, 151, 53, 255],
  },
});
renderer.addClassBreakInfo({
  minValue: 280.5,
  maxValue: 350,
  label: '281 - 350  t/ha',
  symbol: {
    type: 'simple-fill',
    color: [64, 125, 35, 255],
  },
});
renderer.addClassBreakInfo({
  minValue: 350.5,
  maxValue: 400,
  label: '351 - 400  t/ha',
  symbol: {
    type: 'simple-fill',
    color: [33, 97, 17, 255],
  },
});

export const checkNotificationStatus = (state: any, historyNotifications: any) => {
  const resultLayers = state
    .map((aoi: any) => {
      return aoi.layers.items;
    })
    .flat(1)
    .filter((layer: any) => layer.type === 'result')
    .filter((notification: any) => !historyNotifications.includes(notification.id));
  let notifications = [];
  notifications = resultLayers.map((layer: any) => {
    const notification = {
      id: layer.id,
      content: layer.content,
    };
    return notification;
  });
  return notifications;
};

export const formatAois = (aois: any) => {
  return aois.map((aoi: any) => {
    const newAoi = aoi;
    const Limits = findLimitsLayer(JSON.parse(aoi.points));
    const aoiFullExtent = {
      xmin: Limits.minXPoint,
      ymin: Limits.minYPoint,
      xmax: Limits.maxXPoint,
      ymax: Limits.maxYPoint,
      spatialReference: {
        wkid: 4326,
      },
    };
    newAoi['kml'] = new KMLLayer({
      fullExtent: aoiFullExtent,
      portalItem: { id: aoi.id },
      id: aoi.id,
    });
    newAoi.algorithms = formatLayers(aoi.layers.items, newAoi['kml']);

    return newAoi;
  });
};
const formatLayers = (layers: any, parentAoi: any) => {
  return [
    {
      name: 'Fire Detection',
      id: 'fire',
      url: '',
      icon: './icons/fire-detection.svg',
      layers: layers
        .filter((layer: any) => layer.type === 'fire detection')
        .map((layer: any) => {
          layer['kml'] = new KMLLayer({
            fullExtent: parentAoi.fullExtent,
            portalItem: { id: layer.id },
            id: layer.id + '_fire',
          });
          return layer;
        })
        .sort((a: any, b: any) => new Date(a.time).getTime() - new Date(b.time).getTime()),
    },
    {
      name: 'Biomass',
      id: 'population',
      url: '',
      icon: './icons/biomass.svg',
      layers: layers
        .filter((layer: any) => layer.type === 'biomass')
        .map((layer: any) => {
          layer['parentAoi'] = parentAoi;
          layer['kml'] = new ImageryTileLayer({
            portalItem: { id: layer.id },
            id: layer.id + '_biomass',
            title: layer.title,
            renderer: renderer,
            popupTemplate: {
              title: 'Forest Biomass (Metric tonnes per hectare)',
              content: (data: any) => {
                const pointValue1 = parseInt(data.graphic.attributes['Raster.ServicePixelValue']);
                const pointValue2 = pointValue1 * 0.47;
                return `${pointValue1} (t/ha) <br/> ${parseFloat(pointValue2.toString()).toFixed(
                  2
                )} Carbon stock (t/ha)`;
              },
            },
          });
          return layer;
        })
        .sort((a: any, b: any) => new Date(a.time).getTime() - new Date(b.time).getTime()),
    },
  ];
};
export const checkAlertOrAnalysis = (state: any, newState: AreaOfInterest[]) => {
  if (state) {
    if (layersCount(state) < layersCount(newState)) {
      const currentLayers = state
        .map((aoi: any) => {
          return aoi.layers.items;
        })
        .flat(1);
      const newLayers = newState
        .map((aoi: any) => {
          return aoi.layers.items;
        })
        .flat(1)
        .filter((newLayer: any) => !currentLayers.some((pastLayer: any) => pastLayer.id === newLayer.id));
      if (newLayers.some((layer) => layer.content === 'service' && layer.type === 'fire detection')) {
        return 'FIRE_ALERT';
      } else {
        return 'ANALYSIS';
      }
    } else {
      return null;
    }
  } else {
    const isFire = newState.some((area: any) =>
      area.algorithms[0].layers.some((layer: any) => {
        const layerTime = new Date(layer.time);
        return layer.content === 'service' && Date.now() - layerTime.getTime() < 7200000;
      })
    );
    if (isFire) {
      return 'FIRE_ALERT';
    } else {
      return null;
    }
  }
};

const findLimitsLayer = (aoiPoints: any) => {
  const minXPoint = aoiPoints.reduce((min: any, point: any) => (point[0] < min ? point[0] : min), aoiPoints[0][0]);
  const minYPoint = aoiPoints.reduce((min: any, point: any) => (point[1] < min ? point[1] : min), aoiPoints[0][1]);
  const maxXPoint = aoiPoints.reduce((max: any, point: any) => (point[0] > max ? point[0] : max), aoiPoints[0][0]);
  const maxYPoint = aoiPoints.reduce((max: any, point: any) => (point[1] > max ? point[1] : max), aoiPoints[0][1]);
  return { minXPoint, minYPoint, maxXPoint, maxYPoint };
};
