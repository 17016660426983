import { createSlice } from '@reduxjs/toolkit';
import {
  addAoiToDB,
  deleteItem,
  fetchAoisThunk,
  sendAnalysisRequest,
  updateAoiFireSubscription,
  sendKMLRequest,
} from '../Api-thunk/api.service';
import { checkAlertOrAnalysis, checkNotificationStatus, formatAois } from './aoiFunctions';

const initialState = {
  status: '',
  aois: null,
  createAoiState: null as any,
  analysisStatus: [], //empty is no notification needed
  historyNotifications: [],
  fireAlarm: false,
};

export const AoiSlice = createSlice({
  name: 'aoiSlice',
  initialState,
  reducers: {
    createAoiStarted: (state, { payload }) => {
      if (payload.polygon) {
        state.createAoiState = payload.polygon;
      } else {
        state.createAoiState = [];
      }
    },
    createAoiExit: (state) => {
      state.createAoiState = null;
    },
    resetAoiStatus: (state) => {
      state.status = '';
    },
    closeAnalysisToast: (state, { payload }) => {
      state.analysisStatus = [];
    },
    closeFireAlarm: (state) => {
      state.fireAlarm = false;
    },
    openFireAlarm: (state) => {
      state.fireAlarm = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAoisThunk.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(fetchAoisThunk.fulfilled, (state, action) => {
        const newAoisState = formatAois(action.payload);
        state.analysisStatus = checkNotificationStatus(newAoisState, state.historyNotifications);
        const newHistory: any = state.analysisStatus.map((notify: any) => {
          return notify.id;
        });
        state.historyNotifications = [...state.historyNotifications].concat(newHistory);
        switch (checkAlertOrAnalysis(state.aois, newAoisState)) {
          case 'FIRE_ALERT':
            state.fireAlarm = true;
            break;

          default:
        }
        state.status = '';
        state.aois = newAoisState;
      })
      .addCase(addAoiToDB.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(addAoiToDB.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(addAoiToDB.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.createAoiState = null;
      })
      .addCase(updateAoiFireSubscription.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(updateAoiFireSubscription.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(updateAoiFireSubscription.fulfilled, (state, action) => {
        state.status = 'fulfilled';
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(deleteItem.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        state.status = 'fulfilled';
      })
      .addCase(sendAnalysisRequest.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(sendAnalysisRequest.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(sendAnalysisRequest.fulfilled, (state, action) => {
        state.status = 'fulfilled';
      })
      .addCase(sendKMLRequest.rejected, (state, action) => {
        state.status = 'rejected';
      })
      .addCase(sendKMLRequest.pending, (state, action) => {
        state.status = 'pending';
      });
  },
});

export const { createAoiStarted, createAoiExit, resetAoiStatus, closeFireAlarm, openFireAlarm, closeAnalysisToast } =
  AoiSlice.actions;

export default AoiSlice.reducer;
