import { FC } from 'react';
import Button from '../sidebarMenu/components/Button/Button';

import './_fire-popup.scss';

export const FirePopup: FC<{ closePopup: () => void }> = ({ closePopup }) => {
  return (
    <div className='fire-popup'>
      <img className='exit-icon' onClick={closePopup} src='./icons/x.svg' alt='exit' />
      <img className='warning-sign' src='./icons/warning-sign.svg' alt='warning' />
      <h2>Active Fires</h2>
      <p>Be aware! There is a fire in an area you are interested in! Once the event is over, we'll update</p>
      <Button handleClick={closePopup} text='Ok, I Understand' isTextSecondaryColor />
    </div>
  );
};
