import { useState } from 'react';
import { createAoiExit } from '../../features/aoi/AoiSlice';
import { addAoiToDB, fetchAoisThunk } from '../../features/Api-thunk/api.service';
import { removeSketches } from '../../features/map/MapSlice';
import { createAoi } from '../../graphql/mutations';
import { listAois } from '../../graphql/queries';
import { useAppDispatch } from '../../store/hooks/hooks';
import Button from '../sidebarMenu/components/Button/Button';
import { toast } from 'react-toastify';
import { sendKMLRequest } from '../../features/Api-thunk/api.service';

export const NewAoi = (props: { polygon?: any }) => {
  const [name, setName] = useState('');
  const dispatch = useAppDispatch();

  const handleSave = () => {
    if (name !== '') {
      // here we need to add function that create aoi kml in arcgis and return the id
      // To Add Id to AOI insert to payload object
      dispatch(
        sendKMLRequest({
          title: name,
          time: Date.now(),
          points: props.polygon,
        })
      )
        .then((res: any) => {
          dispatch(
            addAoiToDB({ operation: createAoi, payload: { id: res.payload.id, title: name, points: props.polygon } })
          )
            .then(() => {
              dispatch(removeSketches());
              toast.success(`${name} successfully added`);
              dispatch(fetchAoisThunk(listAois));
            })
            .catch(() => {
              toast.error(`${name} failed to be added`);
            });
        })
        .catch(console.log);
    }
  };

  return (
    <div className='new-aoi-container'>
      <div className='new-aoi-content'>
        <button className='exit-button' onClick={() => dispatch(createAoiExit())}>
          <img src='.\icons\arrow-left.svg' alt='exit' />
          <span>Exit</span>
        </button>
        <h3 className='new-aoi-title'>Create New Area of Interest</h3>
        <p className='new-aoi-main-text'>
          Save the polygons of the areas that interest you. Based on those areas, we'll be able to provide our services.
        </p>
        <div className='line'></div>
        <p className='form-field-title'>Area of Interest Name</p>
        <textarea value={name} onChange={(e) => setName(e.target.value)} placeholder='Type Name For Area' />
      </div>
      <div className='buttons-section'>
        <Button text='cancel' handleClick={() => dispatch(createAoiExit())} />
        <Button text='save' isTextSecondaryColor disable={name === ''} handleClick={() => handleSave()} />
      </div>
    </div>
  );
};
