import { useState } from 'react';
import Analysis from '../Analysis/Analysis';
import RealTimeAlgo from '../RealTimeAlgo/RealTimeAlgo';
import TabPicker from '../TabPicker/TabPicker';

const Algorithms = () => {
  const [openTab, setOpenTab] = useState<AlgorithmTabs>('Analysis');

  const changeTab = (tab: AlgorithmTabs) => {
    tab !== openTab && setOpenTab(tab);
  };

  const renderTab = (tab: AlgorithmTabs) => {
    switch (tab) {
      case 'Analysis':
        return <Analysis />;
      case 'Real Time':
        return <RealTimeAlgo />;
    }
  };

  return (
    <>
      <TabPicker openTab={openTab} changeTab={changeTab} />
      {renderTab(openTab)}
    </>
  );
};

export default Algorithms;
