import React, { useState, FC, useRef } from 'react';
import { updateLayers } from '../../../../features/map/MapSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { downloadKml } from '../../../../utils/kml';
import AlgorithmLayers from '../AlgorithmLayers/AlgorithmLayers';
//bootstrap for popover
import Overlay from 'react-bootstrap/Overlay';
import 'bootstrap/dist/css/bootstrap.min.css';
import { deleteAoi, updateAoi } from '../../../../graphql/mutations';
import { deleteItem, fetchAoisThunk, updateAoiFireSubscription } from '../../../../features/Api-thunk/api.service';
import { listAois } from '../../../../graphql/queries';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ConfirmationPopup } from '../../../confirmationPopup/ConfirmationPopup';

export interface AreaOfInterestProps {
  area: AreaOfInterest;
  filter: any;
  moreActionsId: string; //Id of aoi with more actions open
  setMoreActions: React.Dispatch<React.SetStateAction<string>>;
}

const AreaOfInterest: FC<AreaOfInterestProps> = ({ area, filter, moreActionsId, setMoreActions }) => {
  // check if the layer is active on map
  const isActiveLayerOnMap = useAppSelector((state) => state.mapReducer.layersIds).includes(area.id);

  const [isOpen, setIsOpen] = useState(false);
  const [isVisiable, setIsVisable] = useState(isActiveLayerOnMap ? true : false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmUnsubscribe, setConfirmUnsubscribe] = useState(false);

  // for popup actions
  const openMoreActionElement = useRef(null);

  // for filter
  const requestedServices = filter.filter((item: any) => item.service).map((item: any) => item.service);
  const requestedDates = filter.filter((item: any) => item.date).map((item: any) => item.date);

  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent) => {
    switch (event.detail) {
      case 1:
        setIsOpen(!isOpen);
        break;
      case 2:
        handleAOISelected();
        break;
    }
  };

  const isFireActive = () => {
    let flag = false;
    if (area.firesub) {
      flag = area.algorithms[0].layers.some((layer: any) => {
        const layerTime = new Date(layer.time);
        return layer.content === 'service' && Date.now() - layerTime.getTime() < 7200000;
      });
    }
    return flag;
  };

  const handleAOISelected = () => {
    dispatch(updateLayers({ layer: area, isChecked: !isVisiable, isAoi: true }));
    setIsVisable(!isVisiable);
  };

  const removeAOI = () => {
    dispatch(
      deleteItem({
        operation: deleteAoi,
        payload: { id: area.id },
      })
    )
      .then(() => {
        setMoreActions('');
        dispatch(fetchAoisThunk(listAois));
        toast.success(`${area.title} have been removed`);
      })
      .catch((err) => {
        toast.error(`${area.title} failded to removed`);
      });
  };

  const removeSubscription = () => {
    dispatch(
      updateAoiFireSubscription({
        operation: updateAoi,
        payload: { id: area.id, firesub: false },
      })
    )
      .then(() => {
        setMoreActions('');
        dispatch(fetchAoisThunk(listAois));
        toast.success(`${area.title} unsubscribed to Fire Detection`);
      })
      .catch((err) => {
        toast.error(`${area.title} failded to unsubscribed Fire Detection`);
      });
  };

  return (
    <div
      className={`area-of-interest ${isOpen && 'open'} ${isVisiable && 'visiable'} ${
        isFireActive() && 'fire-active'
      } pointer`}
    >
      <div className='area-of-interest-title'>
        <div className='title-inner-container'>
          <button
            onClick={(event) => {
              handleAOISelected();
            }}
            className='visiability-button visiable-button'
          >
            <img src='./icons/eye.svg' alt='visiable-sign' />
          </button>
          <button
            onClick={(event) => {
              handleAOISelected();
            }}
            className='visiability-button unvisiable-button'
          >
            <img src='./icons/eye-disable.svg' alt='visiable-sign' />
          </button>
          <h3 onClick={(e) => handleClick(e)}>{area.title}</h3>
        </div>
        <div className='icons-container'>
          <img className='fire-active-img' src={`./icons/fire-detection-color.svg`} alt='real time indicator' />
          {area.firesub && (
            <img className='real-time-indicator' src={`./icons/real-time.svg`} alt='real time indicator' />
          )}

          <img
            className='more-actions-icon'
            onClick={() => {
              if (moreActionsId !== area.id) {
                setMoreActions(area.id);
              } else {
                setMoreActions('');
              }
            }}
            src={`./icons/more-actions.svg`}
            ref={openMoreActionElement}
            alt='more-actions'
          />
          <span onClick={(e) => handleClick(e)} className={`open-arrow ${isOpen && 'opened'}`}>
            <img className='collapsiable-arrow' src={`./icons/${isOpen ? 'arrow-down' : 'arrow'}.svg`} alt='arrow' />
          </span>
        </div>
        <Overlay target={openMoreActionElement.current} show={moreActionsId === area.id} placement='right'>
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.06)',
                minWidth: '250px',
                cursor: 'pointer',
                marginInlineStart: '5px',
                color: 'black',
                borderRadius: 3,
                ...props.style,
              }}
            >
              <div className={`more-actions`}>
                <span
                  className='action'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                    height: '50px',
                  }}
                  onClick={() => downloadKml(area.title, JSON.parse(area.points))}
                >
                  <img src='./icons/export.svg' alt='export-icon'></img>
                  <p style={{ margin: '0' }}>Export as KML</p>
                </span>
                <span
                  className='action'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                    height: '50px',
                  }}
                  onClick={() => setConfirmDelete(true)}
                >
                  <img src='./icons/delete.svg' alt='export-icon'></img>
                  <p style={{ margin: '0' }}>Delete</p>
                </span>
                {area.firesub && (
                  <span
                    className='action'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                      height: '50px',
                    }}
                    onClick={() => setConfirmUnsubscribe(true)}
                  >
                    <img src='./icons/unsubscribe.svg' alt='export-icon'></img>
                    <p style={{ margin: '0' }}>Unsubscribe</p>
                  </span>
                )}
              </div>
            </div>
          )}
        </Overlay>

        <Popup className='confirm-popup' open={confirmDelete || confirmUnsubscribe}>
          <ConfirmationPopup
            text={
              confirmDelete
                ? 'Are you sure you want to delete this area?'
                : 'Are you sure you want unsubscribe from fire detection?'
            }
            closePopup={() => {
              confirmDelete ? setConfirmDelete(false) : setConfirmUnsubscribe(false);
            }}
            confirmFunction={() => {
              confirmDelete ? removeAOI() : removeSubscription();
            }}
          ></ConfirmationPopup>
        </Popup>
      </div>

      {isOpen && (
        <div className='area-of-interest-extend'>
          {area.algorithms.map((algorithm) => {
            if (
              requestedServices.filter((service: any) => algorithm.name === service).length ||
              requestedServices.length === 0
            ) {
              return (
                <AlgorithmLayers
                  key={algorithm.name}
                  aoiName={area.title}
                  algorithmLayer={algorithm}
                  filterDate={requestedDates}
                  moreActionsId={moreActionsId}
                  setMoreActions={setMoreActions}
                />
              );
            } else {
              return <></>;
            }
          })}
        </div>
      )}
    </div>
  );
};

export default AreaOfInterest;
