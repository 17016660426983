import axios from 'axios';
import { xml2json } from 'xml-js';

const site = process.env.SITE;

export const convertXmlToQGIS = (aoiname: string, title: any, xml: string) => {
  const date = title.substring(title.lastIndexOf(' '), title.length).replace('T', ' ');
  const exportDate = new Date();
  const layer = aoiname + ' ' + title.substring(0, title.lastIndexOf(' '));
  let convertedXml: string;
  let convertedCsv = `Sources: NOAA EUMETSAT NASA ESA\nExported Layer:,${layer}\nLayer Date:,${date}\nExport Date:,${exportDate
    .toISOString()
    .replace('T', ' ')
    .replace('Z', ' ')}\n\nLongitude, Latitude, Power, Temperature, Date, Time\n`;
  //* Add schema to the header
  convertedXml = xml.replace('<Document>', kmlHeaderSchema).split('<Placemark>')[0];
  //* Run on XML object and convert placemarks to extended data
  const placemarks = xml.split('<Placemark>');
  placemarks.shift();
  const convertedPoints = placemarks.map((placemark: string) => {
    let stringOfPlacemark = `<Placemark>
	<ExtendedData><SchemaData schemaUrl="#fire_detection">`;
    const data = placemark.split('<![CDATA[')[1].split(']]>')[0].replace('</br>', '').replace('</br>', '');
    const objData = JSON.parse(xml2json(data, { compact: true, spaces: 4 }));
    const infoElementsObj = objData.div.div.span;
    infoElementsObj.map((oneElement: any) => {
      stringOfPlacemark += `<SimpleData name='${oneElement.b._text.split(':')[0]}'>${oneElement._text}</SimpleData>`;
      convertedCsv += oneElement._text + ',';
      return [oneElement.b._text, oneElement._text];
    });
    convertedCsv = convertedCsv.substring(0, convertedCsv.length - 1) + '\n';

    stringOfPlacemark += `</SchemaData></ExtendedData>` + placemark.split('</description>')[1];
    return stringOfPlacemark;
  });
  convertedPoints.forEach((pointStr: any) => {
    convertedXml += pointStr;
  });
  return [convertedXml, convertedCsv];
};

export const downloadLayerAsCSV = async (title: any, url: any) => {
  const link = document.createElement('a');
  link.href = url;
  link.click();
};

export const downloadLayerForFire = async (aoiname: string, title: string, id: string, exportType: 'csv' | 'kml') => {
  const kml: any = await axios.get(`https://terraspacelab.maps.arcgis.com/sharing/rest/content/items/${id}/data`);
  const [xml, csv] = convertXmlToQGIS(aoiname, title, kml.data);
  const blob = new Blob(exportType === 'csv' ? [csv] : [xml], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${title}.${exportType}`;
  link.href = url;
  link.click();
};

export const downloadKml = (title: string, points: any) => {
  let kml = header(title) + style;
  const topPoint = points.reduce((curr: any, point: any) =>
    curr[1] > point[1] ? curr : curr[1] < point[1] ? point : curr[0] < point[0] ? curr : point
  );
  kml += createPoint(title, topPoint);
  kml += createPolygon(title, points);
  kml += footer;

  const blob = new Blob([kml], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${title}.kml`;
  link.href = url;
  link.click();
};

const header = (title: string) => `<?xml version="1.0" encoding="UTF-8" ?>
  <kml xmlns="http://earth.google.com/kml/2.2">
  <Document><name>${title}</name>`;

const style = `
  <Style id="Aoi">
    <LineStyle><color>501400C8</color><width>2</width></LineStyle>
    <LabelStyle><bgColor>D9D9D900</bgColor><scale>4</scale><color>D9D9D900</color></LabelStyle>
    <PolyStyle><color>50BEBEBE</color></PolyStyle>
  </Style>
  <Style id="activeAoi">
    <LineStyle><color>CF1D3F00</color><width>2</width></LineStyle>
    <LabelStyle><bgColor>CF1D3F00</bgColor><scale>4</scale><color>FFFFFF</color></LabelStyle>
    <PolyStyle><color>50BEBEBE</color></PolyStyle>
  </Style>
  <Style id="topPoint"><IconStyle>
    <scale>1.2</scale>
    <Icon><href>${site}/AOI_icon.png</href></Icon>
  </IconStyle></Style>`;

const createPoint = (title: string, point: any) => `
  <Placemark>
    <name>${title}</name>
    <styleUrl>topPoint</styleUrl>
    <Point>
      <coordinates>${point[0]},${point[1]}</coordinates>
    </Point>
  </Placemark>`;

const createPolygon = (title: string, points: any) => `
  <Placemark> 
    <styleUrl>Aoi</styleUrl>
    <name>${title}</name>
    <Polygon><outerBoundaryIs><LinearRing><coordinates>
      ${points.reduce((str: string, point: any) => (str += point[0] + ',' + point[1] + '\n'), '')}
    </coordinates></LinearRing></outerBoundaryIs></Polygon>
  </Placemark>`;

const kmlHeaderSchema = `<Document><Schema name="fire_detection" id="fire_detection">
  <SimpleField name="Longitude" type="string"></SimpleField>
  <SimpleField name="Latitude" type="string"></SimpleField>
  <SimpleField name="Power" type="string"></SimpleField>
  <SimpleField name="Temperature" type="string"></SimpleField>
  <SimpleField name="Date" type="string"></SimpleField>
  <SimpleField name="Time" type="string"></SimpleField>
</Schema>
`;

const footer = `</Document></kml>`;
