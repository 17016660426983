import { Oval } from 'react-loader-spinner';
import './_loader.scss';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Loader = () => {
  return (
    <Oval
      height={150}
      width={150}
      color='#CF1D3F'
      wrapperClass={'loader-wrapper'}
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor='#CF1D3F'
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export default Loader;
