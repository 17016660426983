import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { deleteLayer } from '../../graphql/mutations';

export const fetchAoisThunk = createAsyncThunk('graphql/fetchAois', async (operation: string, thunkApi) => {
  try {
    const data: any = await API.graphql(graphqlOperation(operation));
    return data.data.listAois.items!;
  } catch (error) {
    throw error;
  }
});

export const addAoiToDB = createAsyncThunk('graphql/createAoi', async (operationAndPayload: any, thunkApi) => {
  try {
    const data: any = await API.graphql(
      graphqlOperation(operationAndPayload.operation, { input: operationAndPayload.payload })
    );
    return data;
  } catch (error) {
    throw error;
  }
});

export const deleteLayerResult = async (id: any) => {
  try {
    await API.graphql(graphqlOperation(deleteLayer, { input: { id } }));
  } catch (err) {}
};

export const deleteItem = createAsyncThunk('graphql/deleteItem', async (operationAndPayload: any, thunkApi) => {
  try {
    const data: any = await API.graphql(
      graphqlOperation(operationAndPayload.operation, { input: operationAndPayload.payload })
    );
    return data;
  } catch (error) {
    throw error;
  }
});

export const updateAoiFireSubscription = createAsyncThunk(
  'graphql/updateAoiSubscription',
  async (operationAndPayload: any, thunkApi) => {
    try {
      const data: any = await API.graphql(
        graphqlOperation(operationAndPayload.operation, { input: operationAndPayload.payload })
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const sendAnalysisRequest = createAsyncThunk('analysisRequest', async (requestedData: any, thunkApi) => {
  try {
    const { service, start_time, end_time, aoi } = requestedData;
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const { id, owner, points } = aoi;
    const data: any = await API.post('analysis', `/${service}`, {
      body: {
        service,
        start_time,
        end_time,
        aoi: { id, owner, points },
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
});

export const sendCSVRequest = async (title: string, layerID: string) => {
  let now = new Date();
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const data: any = await API.post('analysis', `/csv`, {
      body: {
        title,
        id: layerID,
        time:
          now.getFullYear() +
          '-' +
          ('0' + (now.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + now.getDate()).slice(-2) +
          ' ' +
          ('0' + now.getHours()).slice(-2) +
          ':' +
          ('0' + now.getMinutes()).slice(-2) +
          ':' +
          ('0' + now.getSeconds()).slice(-2),
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const sendKMLRequest = createAsyncThunk('kmlRequest', async (requestedData: any, thunkApi) => {
  try {
    const { title, time, points } = requestedData;
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const data: any = await API.post('analysis', `/kml`, {
      body: {
        title,
        time,
        points,
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
});
