import { useState, useEffect, FC, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { updateLayers, updateListLayers } from '../../../../features/map/MapSlice';
import { downloadLayerAsCSV, downloadLayerForFire } from '../../../../utils/kml';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import KMLLayer from "@arcgis/core/layers/KMLLayer";
// import MapImageLayer from '@arcgis/core/layers/MapImageLayer';

//bootstrap for popover
import Overlay from 'react-bootstrap/Overlay';
import 'bootstrap/dist/css/bootstrap.min.css';
import { deleteLayer } from '../../../../graphql/mutations';
import { deleteItem, fetchAoisThunk, sendCSVRequest } from '../../../../features/Api-thunk/api.service';
import { listAois } from '../../../../graphql/queries';
import { ConfirmationPopup } from '../../../confirmationPopup/ConfirmationPopup';

export interface AlgorithmLayerProps {
  aoiName: string;
  algorithmLayer: Layer;
  filterDate: any;
  moreActionsId: string;
  setMoreActions: React.Dispatch<React.SetStateAction<string>>;
}

const AlgorithmLayer: FC<AlgorithmLayerProps> = ({
  aoiName,
  algorithmLayer,
  filterDate,
  moreActionsId,
  setMoreActions,
}) => {
  const activeLayersOnMap = useAppSelector((state) => state.mapReducer.layersIds);

  const [isOpen, setIsOpen] = useState(false);
  const [isSelectAllPreesed, setIsSelectAllPressed] = useState(false);
  const [checkedLayers, setCheckedLayers]: any = useState(activeLayersOnMap);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const layers: any = useAppSelector((state: any) => state.mapReducer.Map.layers?.items);
  const dispatch = useAppDispatch();

  const openMoreActionElement = useRef<any>();

  const layersChecked = useCallback(() => {
    algorithmLayer.layers.forEach((layer: any) => {
      if (layers.some((storeLayer: any) => storeLayer.id === layer.id)) {
        setCheckedLayers((prevCheckedLayers: any) => [...prevCheckedLayers, layer]);
      }
    });
  }, [algorithmLayer.layers, layers]);

  useEffect(() => {
    layersChecked();
  }, [layersChecked, filterDate]);

  const handleLayerSelected = (isChecked: boolean, layer: any) => {
    if (isChecked) {
      const newCheckedLayers = checkedLayers.filter((id: any) => id !== layer.id);
      setCheckedLayers(newCheckedLayers);
    } else {
      setCheckedLayers((prevCheckedLayers: any) => [...prevCheckedLayers, layer.id]);
    }
    dispatch(updateLayers({ layer, isChecked: !isChecked }));
  };

  const layersToRender = () => {
    if (filterDate.length) {
      return algorithmLayer.layers.filter((layer: any) => {
        const date = new Date(layer.time);

        return date > filterDate[0].start && date < filterDate[0].end;
      });
    } else {
      return algorithmLayer.layers;
    }
  };

  const removeLayer = (layerID: any) => {
    dispatch(
      deleteItem({
        operation: deleteLayer,
        payload: { id: layerID },
      })
    )
      .then(() => {
        setMoreActions('');
        dispatch(fetchAoisThunk(listAois));
        toast.success(`Layer have been removed`);
      })
      .catch((err) => {
        toast.error(`Layer failded to removed`);
      });
  };

  const downloadCsv = (title: any, id: any) => {
    sendCSVRequest(title, id).then((result) => {
      downloadLayerAsCSV(title, result);
    });
  };

  const layersShouldRender = layersToRender();

  const showAllLayers = (isChecked: boolean) => {
    setIsSelectAllPressed((prevState: boolean) => !prevState);
    dispatch(updateListLayers({ layers: layersToRender(), isChecked }));
  };

  // Remove Algorithms with no layers
  if (layersShouldRender.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className='algorithm-layers-title'>
        <span className='algorithm-name'>
          <img src={algorithmLayer.icon} alt={algorithmLayer.name} />
          <p onClick={() => setIsOpen(!isOpen)}>{algorithmLayer.name + `(${layersShouldRender.length})`}</p>
          {/* <p onClick={showAllLayers}>here</p> */}
        </span>

        <span onClick={() => setIsOpen(!isOpen)} className={`open-arrow ${isOpen && 'opened'}`}>
          <img className='collapsiable-arrow' src={`./icons/${isOpen ? 'arrow-down' : 'arrow'}.svg`} alt='arrow' />
        </span>
      </div>
      {isOpen && (
        <ul className='algorithm-layers-list'>
          <li key={`${algorithmLayer.id}_show-all`} className={`algorithm-layers-item show-all`}>
            <button className='visiability-button unvisiable-button' onClick={() => showAllLayers(!isSelectAllPreesed)}>
              <img
                src={`./icons/checkbox-${!isSelectAllPreesed ? 'unchecked' : 'checked'}.svg`}
                alt='checkbox select all'
              />
            </button>
            <p className='layer-name' onClick={() => showAllLayers(!isSelectAllPreesed)}>
              {isSelectAllPreesed ? 'Clear All' : 'Select All'}
            </p>
          </li>

          {layersShouldRender.map((layer: any) => {
            return (
              <li
                key={layer.id}
                className={`algorithm-layers-item ${activeLayersOnMap.includes(layer.id) && 'visiable'}`}
              >
                <button
                  onClick={(event) => {
                    handleLayerSelected(true, layer);
                  }}
                  className='visiability-button visiable-button'
                >
                  <img src='./icons/eye.svg' alt='visiable-sign' />
                </button>
                <button
                  onClick={(event) => {
                    handleLayerSelected(false, layer);
                  }}
                  className='visiability-button unvisiable-button'
                >
                  <img src='./icons/eye-disable.svg' alt='visiable-sign' />
                </button>
                <div className='hover-text'>
                  <p className='layer-name'>
                    {layer.title.charAt(0).toUpperCase() + layer.title.substring(1) + ' ' + layer.time.split('.')[0]}
                  </p>
                  <span className='tooltip-text' id={algorithmLayer.name === 'Biomass' ? 'top-bio' : 'top'}>
                    {layer.title.charAt(0).toUpperCase() + layer.title.substring(1) + ' ' + layer.time.split('.')[0]}
                  </span>
                </div>

                <img
                  className='more-actions-icon'
                  ref={openMoreActionElement}
                  onClick={(e) => {
                    openMoreActionElement.current = e.currentTarget;
                    if (moreActionsId !== layer.id) {
                      setMoreActions(layer.id);
                    } else {
                      setMoreActions('');
                    }
                  }}
                  src={`./icons/more-actions.svg`}
                  alt='more-actions'
                />

                <Overlay target={openMoreActionElement.current} show={moreActionsId === layer.id} placement='right'>
                  {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: 'white',
                        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.06)',
                        minWidth: '250px',
                        cursor: 'pointer',
                        marginInlineStart: '5px',
                        color: 'black',
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      <div className={`more-actions`}>
                        <span
                          className='action'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            height: '50px',
                          }}
                          onClick={() => {
                            layer.type === 'fire detection'
                              ? downloadLayerForFire(
                                  aoiName,
                                  layer.title.charAt(0).toUpperCase() +
                                    layer.title.substring(1) +
                                    ' ' +
                                    layer.time.split('.')[0],
                                  layer.id,
                                  'kml'
                                )
                              : downloadCsv(
                                  layer.title.charAt(0).toUpperCase() +
                                    layer.title.substring(1) +
                                    ' ' +
                                    layer.time.split('.')[0],
                                  layer.id
                                );
                          }}
                        >
                          <img src='./icons/export.svg' alt='export-icon'></img>
                          <p>Export as {layer.type === 'fire detection' ? 'KML' : 'CSV'}</p>
                        </span>
                        {layer.type === 'fire detection' && (
                          <span
                            className='action'
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '10px',
                              height: '50px',
                            }}
                            onClick={() => {
                              downloadLayerForFire(
                                aoiName,
                                layer.title.charAt(0).toUpperCase() +
                                  layer.title.substring(1) +
                                  ' ' +
                                  layer.time.split('.')[0],
                                layer.id,
                                'csv'
                              );
                            }}
                          >
                            <img src='./icons/export.svg' alt='export-icon'></img>
                            <p>Export as CSV</p>
                          </span>
                        )}
                        <span
                          className='action'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            height: '50px',
                          }}
                          onClick={() => setConfirmPopup(true)}
                        >
                          <Popup className='confirm-popup' open={confirmPopup}>
                            <ConfirmationPopup
                              text='Are you sure you want to delete this layer?'
                              closePopup={() => setConfirmPopup(false)}
                              confirmFunction={() => removeLayer(layer.id)}
                            ></ConfirmationPopup>
                          </Popup>

                          <img src='./icons/delete.svg' alt='export-icon'></img>
                          <p style={{ margin: '0' }}>Delete</p>
                        </span>
                      </div>
                    </div>
                  )}
                </Overlay>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default AlgorithmLayer;
