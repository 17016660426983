import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

export interface SecondarySidebarProps {
  activities: SidebarActivity[];
  activeOption: SidebarActivity;
  setActivityFunc: (activity: SidebarActivity) => void;
}

const SecondarySidebar: React.FC<SecondarySidebarProps> = ({ activities, activeOption, setActivityFunc }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='secondary-sidebar'>
      <div className='secondary-sidebar-options'>
        {/* to make logo right switch png to svg */}
        <img className='logo' src={'/icons/small-logo.png'} alt='Terra Logo' />
        <div className='log-out'></div>
        {activities.map((activity) => {
          return (
            <img
              key={activity.name}
              className='activity-icon'
              src={activity.name === activeOption.name ? `./icons/${activity.activeIcon}` : `./icons/${activity.icon}`}
              alt={`${activity.name}-icon`}
              onClick={() => setActivityFunc(activity)}
            ></img>
          );
        })}
      </div>
      <div className='user-icon'>
        <img src={'./icons/avatar.svg'} className='user-image pointer' alt='User' onClick={() => setIsOpen(!isOpen)} />
        {isOpen && (
          <div className='sign-out-container' onClick={() => Auth.signOut()}>
            <img src='./icons/log-out.svg' className='sign-out-icon' alt='sign out' />
            <p>Log Out</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondarySidebar;
