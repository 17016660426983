export const mockActivities: SidebarActivity[] = [
  {
    name: 'layers',
    icon: 'layers-icon.svg',
    activeIcon: 'layers-active-icon.svg',
  },
  {
    name: 'algorithms',
    icon: 'algo-icon.svg',
    activeIcon: 'algo-active-icon.svg',
  },
];
