import { FC } from 'react';

export interface TabPickerProps {
  openTab: AlgorithmTabs;
  changeTab: (tab: AlgorithmTabs) => void;
}

const TabPicker: FC<TabPickerProps> = ({ openTab, changeTab }) => {
  return (
    <div className="tab-picker">
      <div
        onClick={() => {
          changeTab('Real Time');
        }}
        className={`algo-tab ${openTab === 'Real Time' && 'selected'}`}
      >
        <h3>Real Time</h3>
      </div>
      <div
        onClick={() => {
          changeTab('Analysis');
        }}
        className={`algo-tab ${openTab === 'Analysis' && 'selected'}`}
      >
        <h3>Analysis</h3>
      </div>
    </div>
  );
};

export default TabPicker;
