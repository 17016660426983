import React, { useEffect, useState } from 'react';
import { layersCount } from '../../../../utils/layers';
import AreaOfInterest from '../AOI/AreaOfInterest';
import Filter from '../Filter/Filter';

export interface LayersProps {
  interestAreas: AreaOfInterest[];
}

const Layers: React.FC<LayersProps> = ({ interestAreas }) => {
  const [isFilterModal, setFilterModal] = useState(false);
  const [filter, setFilter] = useState<any>([]);
  const [moreActionsId, setMoreActionsId] = useState<string>('');

  const renderLayers = (interestAreas: AreaOfInterest[]) => {
    if (interestAreas.length === 0) {
      return (
        <>
          <img className='layers-not-found-image' src={'./icons/layers-not-found.svg'} alt='layers'></img>
          <p className='layers-not-found-text'>You have not created any layers yet</p>
        </>
      );
    } else {
      return interestAreas.map((area) => {
        return (
          <AreaOfInterest
            key={area.id}
            filter={filter}
            area={area}
            moreActionsId={moreActionsId}
            setMoreActions={setMoreActionsId}
          />
        );
      });
    }
  };

  useEffect(() => {
    if (moreActionsId !== '') {
      document.querySelector('.interest-areas-container')?.addEventListener('wheel', (e) => {
        setMoreActionsId('');
      });
    }
  }, []);

  const removeFilter = (index: any) => {
    setFilter((prevFilter: any) => {
      const newFilter = [...prevFilter];
      newFilter.splice(index, 1);
      return newFilter;
    });
  };

  return (
    <div className='layers'>
      <div className='layers-content'>
        <Filter isOpen={isFilterModal} handleAddFilter={setFilter} handleCloseFilter={() => setFilterModal(false)} />
        <h2 className='layers-title'>Layers</h2>
        <div className='layers-info'>
          {/* need to add counting for layers after filter applyed */}
          <p className='layers-counter'>{`${layersCount(interestAreas)} Layers`}</p>
          <span className='filter-label' onClick={() => setFilterModal((prev) => !prev)}>
            <img className='filter-label-img' src='./icons/filter.svg' alt='filter' />
            <p>Filter</p>
          </span>
        </div>
        {!!filter.length && (
          <div className='filters-list'>
            {filter.map((filterItem: any, index: any) => {
              let value = '';
              if (filterItem.date) {
                value = `${filterItem.date.start.getMonth()}/${filterItem.date.start.getDate()}/${filterItem.date.start.getFullYear()}-
                ${filterItem.date.end.getMonth()}/${
                  filterItem.date.end.getDate() - 1
                }/${filterItem.date.end.getFullYear()}`;
              } else {
                value = filterItem.service;
              }
              return (
                <span key={index} className='filter-item'>
                  <p>{value}</p>
                  <img onClick={() => removeFilter(index)} src='./icons/x.svg' alt='filter' />
                </span>
              );
            })}
          </div>
        )}
        <div className='seperate-line'></div>
        <div className='interest-areas-wrapper'>
          <div className={`interest-areas-container ${moreActionsId === '' && 'scrollable'} `}>
            {renderLayers(interestAreas)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layers;
