import React from 'react';
import Select, { SingleValue, ActionMeta } from 'react-select';

//style for rotate arrow when open
const customStyle = {
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
};

export interface DropdownProps {
  options: string[];
  handleSelection: (option: string) => void;
  placeholder?: string;
}

export interface Option {
  value: string;
  label: string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, handleSelection, placeholder }) => {
  const handleSelect = (value: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    handleSelection(value!.value);
  };

  const correctOptions = (options: string[]) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  };

  return (
    <Select
      styles={customStyle}
      placeholder={placeholder}
      onChange={handleSelect}
      options={correctOptions(options)}
    />
  );
};

export default Dropdown;
